import React from 'react';
import styled from '@emotion/styled';
import PropTypes, { InferProps } from 'prop-types';
import Body1 from '../Typography/Body/Body1';
import Button from '../Button';
import Headline4 from '../Typography/Heading/Headline4';

const DashboardHomePagePropertyTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  actions: PropTypes.node,
  statistics: PropTypes.node,
};

type DashboardHomePageProperties = InferProps<
  typeof DashboardHomePagePropertyTypes
>;

export const HomePageButton = styled(Button)`
  margin: 0 8px 0 0;
`;

const HomePageContent = styled(Body1)`
  margin: 1rem 0;
`;

const StatisticsContainer = styled.div`
  margin-top: 4rem;
`;

const DashboardHomePage = ({
  content,
  title,
  actions,
  statistics,
}: DashboardHomePageProperties) => (
  <>
    <Headline4 tag="h1">
      { title }
    </Headline4>
    <HomePageContent tag="div">
      { content }
    </HomePageContent>
    { actions && (
    <HomePageContent tag="div">
      { actions }
    </HomePageContent>
    )}
    { statistics && (
    <StatisticsContainer>
      { statistics }
    </StatisticsContainer>
    )}
  </>
);

DashboardHomePage.propTypes = DashboardHomePagePropertyTypes;

export default DashboardHomePage;
