import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const subtractDays = (days: number, from: Date | string = new Date()) => (
  dayjs.utc(from).subtract(days, 'day')
);

export default subtractDays;
