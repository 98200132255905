import React, { useId } from 'react';
import { OperationVariables } from '@apollo/client';
import ChartComponent from '@fuww/library/src/StatisticChart';
import { DocumentNode } from 'graphql';
import withQuery from '../../lib/withQuery';

interface MonthlyData {
  count: number;
  month: string;
}

type ChartData = Record<string, MonthlyData[]>;

interface DashboardChartProperties {
  data: ChartData;
  label: string;
  fieldName: string;
  id: string;
}

const DashboardChart = ({
  data,
  label,
  fieldName,
  id,
}: DashboardChartProperties) => {
  const chartData = data[fieldName].map(({ count, month }) => ({
    name: month,
    count,
  }));

  return (
    <ChartComponent
      label={label}
      chartData={chartData}
      id={id}
    />
  );
};

const DashboardChartWithQuery = withQuery<
Record<string, MonthlyData[]>,
OperationVariables,
false,
{
  label: string;
  fieldName: string;
  id: string;
}
>(DashboardChart);

interface DashboardChartWithDataProperties {
  label: string;
  query: DocumentNode;
  variables?: OperationVariables;
  fieldName: keyof ChartData;
}

const DashboardChartWithData = ({
  query, variables, label, fieldName,
}: DashboardChartWithDataProperties) => {
  const id = useId();
  return (
    <DashboardChartWithQuery
      errorMessage="Error loading chart data"
      label={label}
      query={query}
      variables={variables}
      fieldName={fieldName}
      id={id}
    />
  );
};

export default DashboardChartWithData;
