import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const getDateRange = () => {
  const min = dayjs.utc().subtract(1, 'year').startOf('month');
  const max = dayjs.utc().subtract(1, 'month').endOf('month');

  return { min, max };
};

export default getDateRange;
