import React from 'react';
import { OperationVariables } from '@apollo/client';
import StatisticBoxComponent from '@fuww/library/src/StatisticBox';
import { DocumentNode } from 'graphql';
import { useIntl } from 'react-intl';
import withQuery from '../../lib/withQuery';
import messages from '../../lib/messages.mjs';
import { amountOfDays } from '../../lib/constants';

interface StatisticBoxProperties {
  data: Record<string, number>;
  fieldName: string;
  label: string;
  isCurrentData?: boolean;
}

const StatisticBox = ({
  data,
  fieldName,
  label,
  isCurrentData,
}: StatisticBoxProperties) => {
  const displayData = data[fieldName];
  const intl = useIntl();

  const displayLabel = isCurrentData
    ? intl.formatMessage(messages.now)
    : `
        ${intl.formatMessage(messages.last)}
        ${amountOfDays}
        ${intl.formatMessage(messages.days)}
      `;

  return (
    <StatisticBoxComponent
      label={label}
      displayLabel={displayLabel}
      displayData={displayData}
    />
  );
};

const StatisticBoxWithQuery = withQuery<
Record<string, number>,
OperationVariables,
false,
{
  label: string;
  fieldName: string;
}
>(StatisticBox);

const StatisticBoxWithData = <V extends OperationVariables>({
  fieldName,
  label,
  query,
  variables,
  isCurrentData = false,
}: {
  fieldName: string;
  label: string;
  query: DocumentNode;
  variables?: V;
  isCurrentData?: boolean;
}) => (
  <StatisticBoxWithQuery
    errorMessage="Error loading statistic data"
    fieldName={fieldName}
    label={label}
    query={query}
    variables={variables}
    isCurrentData={isCurrentData}
  />
  );

export default StatisticBoxWithData;
