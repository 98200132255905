import styled from '@emotion/styled';
import React from 'react';
import { GridCell } from '../Grid';
import Headline6 from '../Typography/Heading/Headline6';
import Headline2 from '../Typography/Heading/Headline2';
import Body2 from '../Typography/Body/Body2';

const StyledGridCell = styled(GridCell)`
  text-align: center;
`;

const Statistic = styled.div`
  margin: 0 1rem 1rem 0;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  padding: 1rem;
  background: #f5f5f5;
`;

const StyledBody2 = styled(Body2)`
  font-style: italic;
`;
interface StatisticBoxProperties {
  label: string;
  displayData: number | string;
  displayLabel: string;
}

const StatisticBox = ({
  label,
  displayLabel,
  displayData,
}: StatisticBoxProperties) => (
  <StyledGridCell xs={12} sm={6} lg={4} xl={2} padding="0">
    <Statistic>
      <Headline6 tag="h4">{label}</Headline6>
      <StyledBody2>{displayLabel}</StyledBody2>
      <Headline2 tag="div">{displayData}</Headline2>
    </Statistic>
  </StyledGridCell>
);

export default StatisticBox;
