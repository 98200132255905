import React from 'react';
import {
  LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer,
} from 'recharts';
import styled from '@emotion/styled';
import Headline5 from '../Typography/Heading/Headline5';
import { GridCell } from '../Grid';

const StyledHeadline5 = styled(Headline5)`
  text-align: center;
`;

const StyledTooltip = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  padding: .75rem 1rem;
  border-radius: .25rem;
  box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.15);
  font-size: .75rem;
`;

interface CustomTooltipProperties {
  payload?: {
    value: number;
  }[];
  label?: string;
  fieldLabel: string;
}

export const CustomTooltip = ({
  payload,
  label: dateValue,
  fieldLabel,
}: CustomTooltipProperties) => (
  fieldLabel && payload?.[0] ? (
    <StyledTooltip>
      {dateValue && <div>{dateValue}</div>}
      <div>
        {fieldLabel}
        :
        {' '}
        {payload[0]?.value}
      </div>
    </StyledTooltip>
    // eslint-disable-next-line unicorn/no-null
  ) : null);

export interface ChartDataPoint {
  name: string;
  count: number;
}
interface ChartProperties {
  chartData: ChartDataPoint[];
  label: string;
  id: string;
}

const Chart = ({ label, chartData, id }: ChartProperties) => (
  <GridCell xs={12} lg={6} padding="1rem">
    <StyledHeadline5 tag="h4">{label}</StyledHeadline5>
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={chartData}
        width={600}
        height={400}
        id={id}
      >
        <Line
          type="monotone"
          dataKey="count"
          stroke="#8884d8"
          isAnimationActive={false}
        />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip
          content={<CustomTooltip fieldLabel={label} />}
        />
      </LineChart>
    </ResponsiveContainer>
  </GridCell>
);

export default Chart;
