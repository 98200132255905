import React, { ElementType } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import MaterialUITypography from '../Typography';

export type Variant = 'body1' | 'body2' | 'button' | 'caption' | 'h1' | 'h2' |
'h3' | 'h4' | 'h5' | 'h6' | 'inherit' | 'overline' | 'subtitle1' | 'subtitle2';

export const bodyPropertyTypes = {
  children: PropTypes.node,
  tag: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'body1', 'body2', 'button', 'caption', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
    'inherit', 'overline', 'subtitle1', 'subtitle2',
  ] as Variant[]),
};

export type BodyProperties = InferProps<typeof bodyPropertyTypes> & {
  variant?: Variant;
  tag?: ElementType;
  className?: string;
};

const Body1 = ({
  children,
  tag,
  className,
  variant,
}: BodyProperties) => (
  <MaterialUITypography
    tag={tag ?? undefined}
    className={className ?? undefined}
    variant={variant ?? undefined}
  >
    {children}
  </MaterialUITypography>
);

export default Body1;
