import React, { ReactElement, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { HomePageButton } from '@fuww/library/src/Dashboard/HomePage';
import HiddenLoader from '@fuww/library/src/HiddenLoader';
import { NextPageWithLayout } from 'next';
import loading from '../../lib/loading';
import LinkAndAnchor from '../../components/LinkAndAnchor';
import { UserContext } from '../../components/UserProvider';
import messages from '../../lib/messages.mjs';
import { saveRouterState } from '../../lib/routerState';
import Head from '../../components/Head';
import Layout from '../../components/Dashboard/Layout/Dynamic';
import DashboardStatistics from '../../components/Dashboard/Statistics';

const DynamicDashboardHomePageComponent = dynamic(() => import(
  '@fuww/library/src/Dashboard/HomePage'
), {
  ssr: false,
  loading,
});

export const DashboardHomePage: NextPageWithLayout = () => {
  const intl = useIntl();
  const { user, loading: userLoading } = useContext(UserContext);
  const router = useRouter();

  return (
    <>
      <Head
        title={intl.formatMessage(messages.indexTitle)}
        description={intl.formatMessage(messages.indexDescription)}
      />
      <DynamicDashboardHomePageComponent
        title="Welcome to the FashionUnited Dashboard"
        content={(
          <>
            FashionUnited is an independent international B2B fashion
            platform. Globally active, the website reaches more than
            1.6 million industry professionals per month. At FashionUnited
            we strive for more fun and efficiency in fashion! That’s why
            we created a one-stop-shop platform where visitors can find
            the latest fashion news, a career center, an events calendar,
            an article archive, various trade statistics, employer branding
            pages and more.
          </>
        )}
        actions={userLoading ? (<HiddenLoader />) : !user && (
          <>
            <LinkAndAnchor
              route="login"
              aria-label="Log in"
              onClick={() => {
                saveRouterState(router);
              }}
              passHref
            >
              <HomePageButton autoFocus>Log in</HomePageButton>
            </LinkAndAnchor>
            <LinkAndAnchor
              route="register"
              aria-label="Register"
              onClick={() => {
                saveRouterState(router);
              }}
              passHref
            >
              <HomePageButton>Register</HomePageButton>
            </LinkAndAnchor>
          </>
        )}
        statistics={<DashboardStatistics />}
      />
    </>
  );
};

DashboardHomePage.getLayout = (page: ReactElement) => (
  <Layout>
    {page}
  </Layout>
);

export default DashboardHomePage;
