import getJobsLocale from './getJobsLocale';

const routesToReplace = {
  'lookbook/categories': 'lookbookCategories',
  'lookbook/category': 'lookbookCategory',
  'lookbook/image': 'lookbookImage',
  'lookbook/upload': 'lookbookUpload',
  'logos/gallery': 'dashboardCategory',
  'logos/image': 'dashboardImage',
  'dashboard/jobs': 'dashboardJobs',
  'applicants-list': 'candidates',
  'dashboard/templates': 'templates',
};

const retrieveDestination = (
  fallbackRoute,
  fallbackQuery,
  locale,
) => {
  const router = JSON.parse(localStorage.getItem('router'));

  if (!router) {
    return { route: fallbackRoute, query: fallbackQuery };
  }
  const trimmedRoute = router.route.slice(1) || 'index';
  const route = locale && [
    'company-profiles',
    'job',
    'jobs',
    'job-apply',
    'jobs-cities',
    'jobs-positions',
    'jobs-landing',
  ].includes(trimmedRoute)
    ? `${trimmedRoute}.${getJobsLocale(locale)}`
    : trimmedRoute;

  return {
    route: routesToReplace[route] || route,
    query: router.query,
  };
};

const clearRouterState = () => {
  localStorage.removeItem('router');
};

const saveRouterState = (router) => {
  localStorage.setItem('router', JSON.stringify(router));
};

export {
  retrieveDestination,
  clearRouterState,
  saveRouterState,
  routesToReplace,
};
