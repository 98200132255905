import React from 'react';
import MaterialUITypography, { TypographyProperties } from '../Typography';

const Headline5 = ({ tag, ...properties }: TypographyProperties) => (
  <MaterialUITypography
    tag={tag}
    variant="h5"
    {...properties}
  />
);

export default Headline5;
